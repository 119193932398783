@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Roboto', sans-serif;
  }

.back {
  cursor: pointer;
}